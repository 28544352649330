<template>
  <div class="px-2 py-5">
    <h1 class="title">Prueba de Medida de eczema orientada al paciente</h1>
    <b-message type="is-info">
      Por favor, marque con un círculo su respuesta para cada una de las siete
      preguntas. Si su hijo es suficientemente mayor para entender las
      preguntas, rellenen el cuestionario juntos. Por favor, deje en blanco
      cualquier pregunta que no sea capaz de responder.
    </b-message>

    <div class="block">
      <span>
        1. Durante la última semana, ¿Cuántos dias ha tenido comezón (picazón)
        en la piel a causa de su eczema?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.POEM_P1"
            :native-value="answers.POEM_P1.POEM_P1_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Ningún día
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.POEM_P1"
            :native-value="answers.POEM_P1.POEM_P1_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            1-2 días
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.POEM_P1"
            :native-value="answers.POEM_P1.POEM_P1_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            3-4 días
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.POEM_P1"
            :native-value="answers.POEM_P1.POEM_P1_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            5-6 días
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.POEM_P1"
            :native-value="answers.POEM_P1.POEM_P1_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Todos los días
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        2. Durante la última semana, ¿Cuántas noches se han interrumpido su
        sueño a causa de su eczema?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.POEM_P2"
            :native-value="answers.POEM_P2.POEM_P2_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Ningún día
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.POEM_P2"
            :native-value="answers.POEM_P2.POEM_P2_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            1-2 días
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.POEM_P2"
            :native-value="answers.POEM_P2.POEM_P2_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            3-4 días
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.POEM_P2"
            :native-value="answers.POEM_P2.POEM_P2_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            5-6 días
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.POEM_P2"
            :native-value="answers.POEM_P2.POEM_P2_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Todos los días
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        3. Durante la última semana, ¿Cuántos días le ha sangrado la piel a
        causa de su eczema?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.POEM_P3"
            :native-value="answers.POEM_P3.POEM_P3_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Ningún día
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.POEM_P3"
            :native-value="answers.POEM_P3.POEM_P3_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            1-2 días
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.POEM_P3"
            :native-value="answers.POEM_P3.POEM_P3_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            3-4 días
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.POEM_P3"
            :native-value="answers.POEM_P3.POEM_P3_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            5-6 días
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.POEM_P3"
            :native-value="answers.POEM_P3.POEM_P3_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Todos los días
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        4. Durante la última semana, ¿Cuántos días ha sido supurado o exudado
        líquido transparente de la piel a causa de su eczema?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.POEM_P4"
            :native-value="answers.POEM_P4.POEM_P4_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Ningún día
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.POEM_P4"
            :native-value="answers.POEM_P4.POEM_P4_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            1-2 días
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.POEM_P4"
            :native-value="answers.POEM_P4.POEM_P4_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            3-4 días
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.POEM_P4"
            :native-value="answers.POEM_P4.POEM_P4_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            5-6 días
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.POEM_P4"
            :native-value="answers.POEM_P4.POEM_P4_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Todos los días
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        5. Durante la última semana, ¿Cuántos días ha tenido la piel agrietada a
        causa de su eczema?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.POEM_P5"
            :native-value="answers.POEM_P5.POEM_P5_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Ningún día
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.POEM_P5"
            :native-value="answers.POEM_P5.POEM_P5_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            1-2 días
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.POEM_P5"
            :native-value="answers.POEM_P5.POEM_P5_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            3-4 días
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.POEM_P5"
            :native-value="answers.POEM_P5.POEM_P5_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            5-6 días
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.POEM_P5"
            :native-value="answers.POEM_P5.POEM_P5_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Todos los días
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        6. En la última semana, ¿Cuántos días se ha descamado la piel a causa
        del eccema?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSix"
            :name="questions.POEM_P6"
            :native-value="answers.POEM_P6.POEM_P6_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Ningún día
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSix"
            :name="questions.POEM_P6"
            :native-value="answers.POEM_P6.POEM_P6_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            1-2 días
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSix"
            :name="questions.POEM_P6"
            :native-value="answers.POEM_P6.POEM_P6_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            3-4 días
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSix"
            :name="questions.POEM_P6"
            :native-value="answers.POEM_P6.POEM_P6_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            5-6 días
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerSix"
            :name="questions.POEM_P6"
            :native-value="answers.POEM_P6.POEM_P6_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Todos los días
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        7. Durante la última semana, ¿Cuántos días ha sentido la piel reseca o
        áspera a causa de su eczema?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSeven"
            :name="questions.POEM_P7"
            :native-value="answers.POEM_P7.POEM_P7_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Ningún día
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSeven"
            :name="questions.POEM_P7"
            :native-value="answers.POEM_P7.POEM_P7_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            1-2 días
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSeven"
            :name="questions.POEM_P7"
            :native-value="answers.POEM_P7.POEM_P7_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            3-4 días
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSeven"
            :name="questions.POEM_P7"
            :native-value="answers.POEM_P7.POEM_P7_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            5-6 días
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerSeven"
            :name="questions.POEM_P7"
            :native-value="answers.POEM_P7.POEM_P7_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Todos los días
          </b-radio>
        </div>
      </div>
    </div>

    <b-message type="is-info">
      <strong>Rango de resultados</strong>
      <br />
      0 a 2 - Sin lesiones o casi sin lesiones
      <br />
      3 a 7 - Leve
      <br />
      8 a 16 - Moderado
      <br />
      17 a 24 - Severo
      <br />
      25 a 28 - Muy severo
    </b-message>

    <div class="block has-text-centered title">
      El resultado es: {{ score }}
      <span>({{ scoreText }})</span>
    </div>

    <div class="buttons is-centered" v-if="!queryMode">
      <b-button class="clear-button" icon-right="block-helper" @click="clear"
        >Limpiar</b-button
      >
      <b-button class="save-button" icon-right="check-bold" @click="save"
        >Guardar</b-button
      >
    </div>
  </div>
</template>

<script>
import { POEM as questions } from "../../../data/questions";
import { POEM as answers } from "../../../data/answers";

export default {
  data() {
    return {
      questions: questions,
      answers: answers,
      answerOne: "",
      answerTwo: "",
      answerThree: "",
      answerFour: "",
      answerFive: "",
      answerSix: "",
      answerSeven: "",
      restoreData: {},
      queryMode: false,
    };
  },
  async created() {
    if (!this.patient && !this.$route.params.surveyId) {
      this.$router.push({ name: "PoemList" }).then(() => {
        this.showToast("No has seleccionado un paciente.", "is-warning", 3000);
      });
    }

    if (this.$route.params.surveyId) {
      this.queryMode = true;
      let data = await this.getSurveyData();
      this.setEvaluationAnswers(data);
    }

    if (this.$route.params.temporalData) {
      this.answerOne = this.$route.params.temporalData.answerOne;
      this.answerTwo = this.$route.params.temporalData.answerTwo;
      this.answerThree = this.$route.params.temporalData.answerThree;
      this.answerFour = this.$route.params.temporalData.answerFour;
      this.answerFive = this.$route.params.temporalData.answerFive;
      this.answerSix = this.$route.params.temporalData.answerSix;
      this.answerSeven = this.$route.params.temporalData.answerSeven;
    }

    if (!this.$route.params.temporalData && !this.$route.params.surveyId) {
      this.initialAnswer();
    }

    this.$global.$on("restore", () => {
      this.restoreForm();
    });
  },
  computed: {
    score() {
      return (
        parseInt(this.answerOne || 0) +
          parseInt(this.answerTwo || 0) +
          parseInt(this.answerThree || 0) +
          parseInt(this.answerFour || 0) +
          parseInt(this.answerFive || 0) +
          parseInt(this.answerSix || 0) +
          parseInt(this.answerSeven || 0) || 0
      );
    },

    isIncompleted() {
      return (
        this.answerOne === "" ||
        this.answerTwo === "" ||
        this.answerThree === "" ||
        this.answerFour === "" ||
        this.answerFive === "" ||
        this.answerSix === "" ||
        this.answerSeven === ""
      );
    },
    scoreText() {
      let text = "";

      if (parseInt(this.score) >= 0 && this.score <= 2) {
        text = "Sin lesiones o casi sin lesiones";
      }

      if (this.score >= 3 && this.score <= 7) {
        text = "Leve";
      }

      if (this.score >= 8 && this.score <= 16) {
        text = "Moderado";
      }

      if (this.score >= 17 && this.score <= 24) {
        text = "Severo";
      }

      if (this.score >= 25 && this.score <= 28) {
        text = "Muy severo";
      }

      return text;
    },
    userId: {
      get() {
        return this.$store.state.user.userData?.id;
      },
    },
    patient: {
      get() {
        return this.$store.state.patient.patientData;
      },
    },
  },
  methods: {
    cleanForms() {
      this.answerOne = "";
      this.answerTwo = "";
      this.answerThree = "";
      this.answerFour = "";
      this.answerFive = "";
      this.answerSix = "";
      this.answerSeven = "";
    },
    clear() {
      this.restoreData.answerOne = this.answerOne;
      this.restoreData.answerTwo = this.answerTwo;
      this.restoreData.answerThree = this.answerThree;
      this.restoreData.answerFour = this.answerFour;
      this.restoreData.answerFive = this.answerFive;
      this.restoreData.answerSix = this.answerSix;
      this.restoreData.answerSeven = this.answerSeven;

      this.initialAnswer();
      this.clearTemporalSurveys();

      this.showUndoDialog("Se limpiaron los datos de  la encuesta POEM.");
    },
    async save() {
      try {
        this.changeLoaderStatus(true);
        let data = {
          surveyCode: "POEM",
          result: String(this.score),
          appreciation: this.scoreText,
          doctorId: String(this.userId),
          patientId: String(this.patient.document),
          detail: this.setAnswers(),
        };

        await this.$surveyService.saveSurvey(data);
        this.changeLoaderStatus(false);
        this.showToast(
          "Encuesta enviada satisfactoriamente.",
          "is-success",
          3000
        );
        this.scrollTop();
        this.initialAnswer();
        this.clearTemporalSurveys();
      } catch (error) {
        this.changeLoaderStatus(false);
        this.showToast("Ha ocurrido un error inesperado.", "is-danger", 3000);
      }
    },
    setAnswers() {
      let question1 = null;
      let question2 = null;
      let question3 = null;
      let question4 = null;
      let question5 = null;
      let question6 = null;
      let question7 = null;

      Object.keys(this.answers[questions.POEM_P1]).forEach((key) => {
        if (this.answers[questions.POEM_P1][key] == this.answerOne) {
          question1 = {
            questionCode: questions.POEM_P1,
            answerCode: key,
            value: this.answerOne,
          };
        }
      });

      Object.keys(this.answers[questions.POEM_P2]).forEach((key) => {
        if (this.answers[questions.POEM_P2][key] == this.answerTwo) {
          question2 = {
            questionCode: questions.POEM_P2,
            answerCode: key,
            value: this.answerTwo,
          };
        }
      });

      Object.keys(this.answers[questions.POEM_P3]).forEach((key) => {
        if (this.answers[questions.POEM_P3][key] == this.answerThree) {
          question3 = {
            questionCode: questions.POEM_P3,
            answerCode: key,
            value: this.answerThree,
          };
        }
      });

      Object.keys(this.answers[questions.POEM_P4]).forEach((key) => {
        if (this.answers[questions.POEM_P4][key] == this.answerFour) {
          question4 = {
            questionCode: questions.POEM_P4,
            answerCode: key,
            value: this.answerFour,
          };
        }
      });

      Object.keys(this.answers[questions.POEM_P5]).forEach((key) => {
        if (this.answers[questions.POEM_P5][key] == this.answerFive) {
          question5 = {
            questionCode: questions.POEM_P5,
            answerCode: key,
            value: this.answerFive,
          };
        }
      });

      Object.keys(this.answers[questions.POEM_P6]).forEach((key) => {
        if (this.answers[questions.POEM_P6][key] == this.answerSix) {
          question6 = {
            questionCode: questions.POEM_P6,
            answerCode: key,
            value: this.answerSix,
          };
        }
      });

      Object.keys(this.answers[questions.POEM_P7]).forEach((key) => {
        if (this.answers[questions.POEM_P7][key] == this.answerSeven) {
          question7 = {
            questionCode: questions.POEM_P7,
            answerCode: key,
            value: this.answerSeven,
          };
        }
      });

      return [
        question1,
        question2,
        question3,
        question4,
        question5,
        question6,
        question7,
      ];
    },
    validate() {
      if (!this.isIncompleted) {
        this.save();
      } else {
        this.showToast("Por favor completa la encuesta.", "is-warning", 3000);
      }
    },
    restoreForm() {
      this.answerOne = this.restoreData.answerOne;
      this.answerTwo = this.restoreData.answerTwo;
      this.answerThree = this.restoreData.answerThree;
      this.answerFour = this.restoreData.answerFour;
      this.answerFive = this.restoreData.answerFive;
      this.answerSix = this.restoreData.answerSix;
      this.answerSeven = this.restoreData.answerSeven;

      this.saveTermporalSulrvey();
    },
    saveTermporalSulrvey() {
      this.$session.set("temporalSurvey", {
        module: {
          name: "POEM",
          route: "Poem",
        },
        survey: {
          answerOne: this.answerOne,
          answerTwo: this.answerTwo,
          answerThree: this.answerThree,
          answerFour: this.answerFour,
          answerFive: this.answerFive,
          answerSix: this.answerSix,
          answerSeven: this.answerSeven,
        },
      });
    },
    setEvaluationAnswers(data) {
      data.evaluationDetails.forEach((survey) => {
        if (survey.question.code == "POEM_P1") {
          this.answerOne = survey.value;
        }

        if (survey.question.code == "POEM_P2") {
          this.answerTwo = survey.value;
        }

        if (survey.question.code == "POEM_P3") {
          this.answerThree = survey.value;
        }

        if (survey.question.code == "POEM_P4") {
          this.answerFour = survey.value;
        }

        if (survey.question.code == "POEM_P5") {
          this.answerFive = survey.value;
        }

        if (survey.question.code == "POEM_P6") {
          this.answerSix = survey.value;
        }

        if (survey.question.code == "POEM_P7") {
          this.answerSeven = survey.value;
        }
      });
    },
    initialAnswer() {
      this.answerOne = this.answers.POEM_P1.POEM_P1_R1;
      this.answerTwo = this.answers.POEM_P2.POEM_P2_R1;
      this.answerThree = this.answers.POEM_P3.POEM_P3_R1;
      this.answerFour = this.answers.POEM_P4.POEM_P4_R1;
      this.answerFive = this.answers.POEM_P5.POEM_P5_R1;
      this.answerSix = this.answers.POEM_P6.POEM_P6_R1;
      this.answerSeven = this.answers.POEM_P7.POEM_P7_R1;
    },
  },
};
</script>
